<template>
  <div
    v-if="layout === 'custom'"
    class="position-absolute w-100 min-height-300 top-0"
    :style="{
      'background-image': 'url(' + image + ')',
      'background-position-y': '50%'
    }"
  >
    <!--span class="mask bg-success opacity-6"></span-->
  </div>
  <div
    v-show="layout === 'default'"
    class="min-height-300 position-absolute w-100"
    :class="`${darkMode ? 'bg-transparent' : 'bg-new'}`"
  />

  <aside
    id="sidenav-main"
    class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 ms-4"
    :class="` ${sidebarType} ${
      isRTL ? 'fixed-end me-3 rotate-caret' : 'fixed-start ms-3'
    } ${layout === 'landing' ? 'bg-transparent shadow-none' : ''}`"
    :style="{ zIndex: 1040 }"
  >
    <div style="margin-left: -20px; text-align: center;" class="sidenav-header">
      <i 
        id="iconSidenav"
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
      ></i>
      <router-link class="m-0 navbar-brand" to="/">
        <img
          :src="
            layout === 'landing' || darkMode || sidebarType === 'bg-default'
              ? logoWhite
              : logo
          "
          class="navbar-brand-img"
          style="transform: scale(2.0); margin-left:20px; margin-top:10px"
          alt="main_logo"
        />
        <br><br>
        <span style="font-size: 11pt;" class="ms-2 font-weight-bold">Protego Savings & Gap Proposal</span>
      </router-link>
    </div>
    <br><br>
    <hr class="mt-0 horizontal dark" />
    <sidenav-list />
  </aside>
</template>

<script>
import SidenavList from "./SidenavList.vue";
import logo from "@/assets/img/logo-ct-dark.png";
import logoWhite from "@/assets/img/logo-ct.png";
import image from "@/assets/img/profile-layout-header.jpg";

import { mapState } from "vuex";
export default {
  name: "Index",
  components: {
    SidenavList
  },
  data() {
    return {
      logo,
      logoWhite,
      image
    };
  },
  computed: {
    ...mapState(["layout", "isRTL", "sidebarType", "darkMode"]),
  },
  beforeUnmount() {
    this.$store.state.image;
  }
};
</script>

<style scoped>
        .bg-new {
            height: 88%; /* Adjust height as needed */
            background: 
              linear-gradient(to bottom, #488ee3, 98%, #b3d1f7, white);
        }
</style>

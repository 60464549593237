// src/store/LoadingState.js
import { reactive } from 'vue';

// Create a reactive state for the loading status
const loadingState = reactive({
  isLoading: false,
});

// Function to set the loading status
export function setLoading(isLoading) {
  loadingState.isLoading = isLoading;
}

// Function to use the loading state
export function useLoadingState() {
  return loadingState;
}

<template>
  <div class="card announcement-card">
    <div class="p-3 pb-0 card-header">
      <div class="row">
        <div class="col-8 d-flex">
          <div class="d-flex flex-column justify-content-center">
            <h6 class="mb-0 text-sm">Campaign Management</h6>
          </div>
        </div>
        <div class="col-4">
          <span
            class="badge ms-auto float-end"
            :class="`bg-gradient-${badge.color}`"
            >{{ badge.label }}</span
          >
        </div>
      </div>
    </div>
    <div class="p-3 pt-1 card-body">
      <h6>{{ title }}</h6>
      <p class="text-sm mb-2">{{ description }}</p> <!-- Reduced margin here -->

      <!-- Summary Section with Button Positioned to the Right -->
      <div class="pt-2 bg-gray-100 d-flex justify-content-between align-items-center border-radius-lg">
        <div class="summary-content">
          <p class="mb-1"><strong>{{ summary.campaignType }}</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ summary.progress }}%</p>
          <p class="mb-1">Expiration Date: {{ summary.expirationDate }}</p>
        </div>
        <div class="action-button">
          <a :href="action.route" class="mb-0 btn btn-outline-dark">
            {{ action.label }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AnnouncementCard",
  props: {
    by: {
      type: Object,
      default: () => {},
      image: String,
      name: String,
      date: String,
    },
    badge: {
      type: Object,
      default: () => {},
      color: String,
      label: String,
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    value: {
      type: Object,
      currency: String,
      amount: String,
      method: String,
      default: () => {},
    },
    action: {
      type: Object,
      route: String,
      label: String,
      default: () => {},
    },
    summary: {
      type: Object,
      default: () => ({
        campaignType: "Standard Campaign",  // Default values for now
        progress: 57,                      // Default progress
        expirationDate: "December 31, 2024", // Default expiration date
      }),
    },
  },
};
</script>

<style scoped>
.announcement-card {
  min-height: 200px;
  max-height: 250px;
  overflow-y: auto;
}

.bg-gray-100 {
  background-color: #f8f9fa;
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.summary-content {
  max-width: 70%; /* Adjust this width as needed */
}

.action-button {
  display: flex;
  justify-content: flex-end;
}

.mb-2 {
  margin-bottom: 0.5rem; /* Reduced margin below description */
}

.p-2 {
  padding: 0.5rem; /* Reduced padding for summary section */
}
</style>

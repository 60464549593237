<template>
  <div>
    <!-- Existing service list -->
    <div class="card">
      <div class="p-3 card-header">
        <div class="row">
          <div class="col-md-6">
            <h6 class="mb-0">{{ header.title }}</h6>
          </div>
          <div class="col-md-6 d-flex justify-content-end align-items-center">
            <small>{{ header.dateTime }}</small>
          </div>
        </div>
        <hr class="mb-0 horizontal dark" />
      </div>
      <div class="p-3 pt-0 card-body">
        <ul class="list-group list-group-flush" data-toggle="checklist">
          <li v-for="(service, index) in services" :key="index"
            class="py-0 mb-3 border-0 list-group-item flex-column align-items-start ps-0">
            <div class="checklist-item checklist-item-primary ps-2 ms-3">
              <div class="d-flex align-items-center">
                <argon-checkbox :id="service.id" :name="service.name" :checked="true" />
                <h6 class="mb-0 text-sm text-dark font-weight-bold">{{ service.name }}</h6>
              </div>
              <div class="mt-3 d-flex align-items-center ms-4 ps-1">
                <div>
                  <p class="mb-0 text-xs text-secondary font-weight-bold">Date</p>
                  <span class="text-xs font-weight-bolder">{{ service.date }}</span>
                </div>
                <div class="ms-auto">
                  <p class="mb-0 text-xs text-secondary font-weight-bold">High Savings</p>
                  <span class="text-xs font-weight-bolder">{{ service.highSavings }}</span>
                </div>
                <div class="mx-auto">
                  <p class="mb-0 text-xs text-secondary font-weight-bold">Low Savings</p>
                  <span class="text-xs font-weight-bolder">{{ service.lowSavings }}</span>
                </div>
              </div>
            </div>
            <hr class="mt-4 mb-0 horizontal dark" />
          </li>
        </ul>
        <button class="btn btn-primary" @click="openModal">Add New Service</button>
      </div>
    </div>

    <!-- Modal for adding a new service -->
    <div v-if="isModalOpen" class="modal-wrapper">
      <div class="background-overlay"></div>
      <div class="modal-container">
        <h2 class="title">Add New Service</h2>
        <form @submit.prevent="submitForm">
          <div class="form-group">
            <label for="serviceName" class="form-label">Service Name</label>
            <input type="text" id="serviceName" v-model="newService.name" class="form-input"
              placeholder="Enter service name" required />
          </div>

          <div class="form-group">
            <label for="highSavings" class="form-label">High Savings</label>
            <input type="text" id="highSavings" v-model="newService.highSavings" class="form-input"
              placeholder="Enter high savings" required />
          </div>

          <div class="form-group">
            <label for="lowSavings" class="form-label">Low Savings</label>
            <input type="text" id="lowSavings" v-model="newService.lowSavings" class="form-input"
              placeholder="Enter low savings" required />
          </div>

          <!-- Button container for cancel/save -->
          <div class="button-container">
            <button type="button" @click="closeModal" class="cancel-button">Cancel</button>
            <button type="submit" class="save-button">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonCheckbox from "@/components/ArgonCheckbox.vue";

export default {
  name: "TodoList",
  components: {
    ArgonCheckbox,
  },
  props: {
    header: {
      type: Object,
      title: String,
      dateTime: String,
      required: true,
    },
  },
  data() {
    return {
      services: [],
      isModalOpen: false, // Controls modal visibility
      newService: {
        name: "",
        highSavings: "",
        lowSavings: "",
      },
    };
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    submitForm() {
      // Add the new service to the list
      const newService = {
        id: this.services.length + 1,
        name: this.newService.name,
        date: new Date().toLocaleDateString(),
        highSavings: this.newService.highSavings,
        lowSavings: this.newService.lowSavings,
      };

      this.services.push(newService);

      // Clear form and close modal
      this.newService.name = "";
      this.newService.highSavings = "";
      this.newService.lowSavings = "";
      this.closeModal();
    },
  },
  mounted() {
    // Example services
    this.services = [
      { id: 1, name: "Consulting Service A", date: "24 March 2019", highSavings: "$500", lowSavings: "$100" },
      { id: 2, name: "Consulting Service B", date: "25 March 2019", highSavings: "$600", lowSavings: "$200" },
      { id: 3, name: "Consulting Service C", date: "26 March 2019", highSavings: "$700", lowSavings: "$300" },
    ];
  },
};
</script>

<style scoped>
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2050;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.7;
  z-index: 2049;
}

.modal-container {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  max-width: 50vw;
  width: 100%;
  max-height: 85vh;
  overflow-y: auto;
  z-index: 2051;
  position: relative;
  border: 2px solid #007bff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-label {
  font-size: 14px;
  margin-bottom: 8px;
  display: block;
}

.form-input {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.cancel-button,
.save-button {
  padding: 10px 20px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  height: 50px;
}

.cancel-button {
  background-color: #ccc;
  color: white;
  margin-right: 10px;
}

.save-button {
  background-color: #007bff;
  color: white;
}
</style>

<template>
  <div v-if="isOpen" class="modal-wrapper">
    <!-- Background overlay -->
    <div class="background-overlay"></div>

    <!-- Modal container -->
    <div class="modal-container">
      <h2 class="title">Save Campaign</h2>
      <form @submit.prevent="submitForm">
        <!-- Campaign Name Input -->
        <div class="form-group">
          <label for="campaignName" class="form-label">Campaign Name</label>
          <input type="text" id="campaignName" v-model="campaignName" class="form-input"
            placeholder="Enter campaign name" required />
        </div>

        <!-- Buttons Container -->
        <div class="button-container" style="flex-grow: 1; display: flex; justify-content: flex-end;">
          <button type="button" @click="closeModal" class="cancel-button">
            Cancel
          </button>
          <button type="submit" class="save-button">
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    isOpen: {
      type: Boolean,
      default: true,
    },
    accountId: {
      type: Number,
      required: true, // Make sure the account ID is passed in
    },
  },
  data() {
    return {
      campaignName: '',
    };
  },
  methods: {
    async submitForm() {
      // Create the payload with account ID and campaign name
      const campaignData = {
        questionnaire_session_id: this.accountId,
        name: this.campaignName,
      };

      try {
        const accessToken = localStorage.getItem("access_token");
        const url = `${process.env.VUE_APP_API_BASE_URL}/save-template`;

        const response = await axios.post(url, campaignData, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });

        console.log("Campaign saved successfully:", response.data);
        this.$emit('save', campaignData);
        this.closeModal();
      } catch (error) {
        console.error("Error saving campaign:", error);
      }
    },
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
/* Same styles as provided earlier */
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2050;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.7;
  z-index: 2049;
}

.modal-container {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  max-width: 50vw;
  width: 100%;
  max-height: 85vh;
  overflow-y: auto;
  z-index: 2051;
  position: relative;
  border: 2px solid #007bff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-label {
  font-size: 14px;
  margin-bottom: 8px;
  display: block;
}

.form-input {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.cancel-button,
.save-button {
  padding: 10px 20px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  height: 50px;
}

.cancel-button {
  background-color: #ccc;
  color: white;
  margin-right: 10px;
}

.save-button {
  background-color: #007bff;
  color: white;
}
</style>

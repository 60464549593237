import { fetchAgencyStyles, updateAgencyStyle } from "../services/agency.service.js";

export const agencyStyle = {
  namespaced: true,
  state: {
    currentStyle: {
      primary: '',
      text: '',
      logo: null
    },
    phone: ''
  },
  mutations: {
    SET_AGENCY_STYLE(state, { style, phone }) {
      state.currentStyle = {
        primary: `#${style.primary_color1.toString(16).padStart(6, '0')}`,
        text: `#${style.primary_color2.toString(16).padStart(6, '0')}`,
        logo: `data:image/png;base64,${style.logo}`
      };
      state.phone = phone;
    },
    CLEAR_AGENCY_STYLE(state) {
      console.log("CLEARING AGENCY STYLE on logout");
      state.currentStyle = {
        primary: '',
        text: '',
        logo: null
      };
      state.phone = '';
    }
  },
  actions: {
    async fetchAgencyStyle({ commit }) {
      try {
        const { data } = await fetchAgencyStyles();
        commit('SET_AGENCY_STYLE', data);
      } catch (error) {
        console.error('Error fetching agency styles:', error);
      }
    },
    async updateAgencyStyle({ commit }, styleData) {
      try {
        const response = await updateAgencyStyle(styleData);
        commit('SET_AGENCY_STYLE', { style: styleData, phone: styleData.phone });
        return response;
      } catch (error) {
        console.error('Error updating agency style:', error);
      }
    }
  },
  getters: {
    currentStyle: state => state.currentStyle,
    phone: state => state.phone
  }
};

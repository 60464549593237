<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-8 col-12">
        <div class="row">
          <div class="col-lg-4 col-12">
            <!-- Statistics Card with Manage Button -->
            <statistics-card title="Estimated Earnings" :count="`$${formatNumber(estimatedEarnings)}`"
              :badge="{ color: '', label: '' }" :action="{ label: 'Manage' }" :onClick="openModal" />

          </div>
          <div class="mt-4 col-lg-4 col-md-6 col-12 mt-lg-0">
            <mini-statistics-card title="Annual Premium" :value="`$${formatNumber(annualPremium)}`" :icon="{
              component: 'ni ni-money-coins',
              background: 'bg-gradient-dark',
              shape: 'rounded-circle',
            }" />
            <mini-statistics-card title="Gap Liability" :value="`$${formatNumber(gapLiability)}`" :icon="{
              component: 'ni ni-planet',
              background: 'bg-gradient-dark',
              shape: 'rounded-circle',
            }" />
          </div>
          <div class="mt-4 col-lg-4 col-md-6 col-12 mt-lg-0">
            <mini-statistics-card title="Consulting Savings" :value="`$${formatNumber(consultingSavings)}`" :icon="{
              component: 'ni ni-world',
              background: 'bg-gradient-dark',
              shape: 'rounded-circle',
            }" />
            <mini-statistics-card title="Probability to close" :value="formatPercent(probabilityToClose)" :icon="{
              component: 'ni ni-shop',
              background: 'bg-gradient-dark',
              shape: 'rounded-circle',
            }" />
          </div>
        </div>
      </div>
      <div class="mt-4 col-lg-4 col-12 mt-lg-0">
        <announcement-card
          description="9-Triggers to Bind-it is our flagship campaign automation tool. It is designed to automate your campaigns and client correspondence."
          :by="{
            image: image,
            name: 'Account Management System',
            date: '2h ago',
          }" :badge="{ color: 'info', label: 'Book Automation' }" :value="{
            label: 'Tasks',
            count: '5',
            unit: 'remaining',
          }" :action="{
            route: `/account/${accountId}`,
            label: '9-Triggers to Bind-it'
          }" :summary="{
            campaignType: 'Standard Campaign',
            progress: 57,
            expirationDate: 'October 31, 2024'
          }" />
      </div>
    </div>
    <div class="mt-4 row">
      <div class="col-lg-8 col-12">
        <todo-list :accountId="accountId" :header="{ title: 'Service Strategy' }" />

      </div>
      <div class="mt-4 col-lg-4 col-12 mt-lg-0">
        <progress-line-chart title="Tasks" :count="480" :progress="60" :chart="{
          labels: [
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ],
          data: [40, 45, 42, 41, 40, 43, 40, 42, 39],
        }" />
        <!--progress-doughnut-chart
          title="Projects"
          :count="115"
          :chart="{
            labels: [
              {
                label: 'Done',
                isCompleted: true,
              },
              {
                label: 'In progress',
                isCompleted: false,
              },
            ],
            datasets: {
              label: 'Projects',
              data: [75, 25],
            },
          }"
        /-->
      </div>
    </div>

    <!-- Modal Component -->
    <edit-account-modal v-if="isModalOpen" :isOpen="isModalOpen" :accountData="{
      accountId: accountId,
      name: accountName,
      email: contactEmail,
      numberOfEmployees: numberOfEmployees,
      expirationDate: xDate,
      annualPremium: annualPremium,
      probabilityToClose: probabilityToClose
    }" @close="closeModal" @save="saveAccountDetails" />
  </div>
</template>

<script>
import StatisticsCard from "./components/StatisticsCard.vue";
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import AnnouncementCard from "./components/AnnouncementCard.vue";
import ProgressLineChart from "@/examples/Charts/ProgressLineChart.vue";
import TodoList from "./components/TodoList.vue";
import EditAccountModal from "@/components/EditProposalAccountModal.vue";
import image from "@/assets/img/team-3.jpg";
import axios from "axios";
import eventBus from "@/services/event-bus.service.js";
import { nextTick } from 'vue';

export default {
  name: "General",
  components: {
    MiniStatisticsCard,
    AnnouncementCard,
    ProgressLineChart,
    TodoList,
    StatisticsCard,
    EditAccountModal,
  },
  data() {
    return {
      contactEmail: '',
      numberOfEmployees: 0,
      xDate: '',
      image,
      estimatedEarnings: 0,
      annualPremium: 0,
      gapLiability: 0,
      consultingSavings: 0,
      signUps: 0,
      probabilityToClose: 0,
      isModalOpen: false,
      accountName: '',
    };
  },
  props: {
    accountId: {
      type: String,
      required: true,
    },
  },
  async mounted() {
    await this.fetchStatistics();
  },
  beforeUnmount() {
    eventBus.emit("toggleHeaderVisibility", false); // Hide the header on unmount
    eventBus.emit("toggleDaysToClose", false);
  },
  methods: {
    async fetchStatistics() {
      try {
        const accessToken = localStorage.getItem("access_token");
        const accountId = this.$route.params.accountId;

        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE_URL}/get-statistics/${accountId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        console.log("RESPONSE: ", response);
        this.accountName = response.data.accountName;
        //await eventBus.emit("updateHeaderTitle", this.accountName);
        //await eventBus.emit("toggleHeaderVisibility", true);


        this.contactEmail = response.data.contactEmail;
        this.numberOfEmployees = response.data.numOfEmployees;
        this.xDate = response.data.xDate;
        const values = response.data.values;

        this.estimatedEarnings = values.estimatedEarnings;
        this.annualPremium = values.annualPremium;
        this.gapLiability = values.gapLiability;
        this.consultingSavings = values.consultingSavings;
        this.signUps = values.signUps;
        this.probabilityToClose = values.probabilityToClose;

        const currentDate = new Date();
        const targetDate = new Date(this.xDate);
        const timeDifference = targetDate - currentDate;
        let daysToClose = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

        await nextTick();
        setTimeout(() => {
          eventBus.emit("updateHeaderTitle", this.accountName);
          eventBus.emit("toggleHeaderVisibility", true);
          eventBus.emit("updateDaysToClose", daysToClose);
          eventBus.emit("toggleDaysToClose", true);
        }, 100);

      } catch (error) {
        console.error("Error fetching statistics:", error);
      }
    },
    formatNumber(number) {
      return number.toLocaleString('en-US', { maximumFractionDigits: 0 });
    },
    formatPercent(number) {
      return (number / 100).toLocaleString('en-US', {
        style: 'percent',
        maximumFractionDigits: 2, // Adjust the number of decimal places
        minimumFractionDigits: 2  // Ensure it always shows two decimal places
      });
    },

    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    saveAccountDetails(updatedDetails) {
  console.log("Updated account details:", updatedDetails);

  // Update local data
  this.accountName = updatedDetails.name;
  this.contactEmail = updatedDetails.email;
  this.numberOfEmployees = updatedDetails.numberOfEmployees;
  this.xDate = updatedDetails.expirationDate;
  this.annualPremium = updatedDetails.annualPremium;

  // Close the modal
  this.closeModal();

  // Refresh the page
  window.location.reload();
},

  },
};
</script>

<template>
  <div class="py-4 container mx-auto">
    <div class="flex">
      <!-- Kanban Board Section -->
      <div class="kanban-grid">
        <div v-for="board in boards" :key="board.id"
          :class="['kanban-column', { 'expanded-kanban-column': isExpanded(board.id) }]">

          <div class="bg-white rounded shadow-lg p-4 border border-gray-200 kanban-item-container">
            <div class="flex items-center justify-between">
              <h2 @click="toggleExpand(board.id)" class="cursor-pointer text-lg font-semibold text-gray-700">{{
                board.title }}</h2>
              <div class="flex items-center">
                <span class="text-sm font-normal text-gray-500 ml-4">
                  {{ board.scheduled_date }}
                </span>
                <span class="cursor-pointer text-gray-500 ml-2" @click="toggleExpand(board.id)">
                  <span v-if="!isExpanded(board.id)">&#x25BC;</span> <!-- Down arrow -->
                  <span v-else>&#x25B2;</span> <!-- Up arrow -->
                </span>
              </div>
            </div>



            <br>
            <!-- Scrollable Container (expands or collapses) -->
            <div class="kanban-scrollable" :class="{ 'expanded': isExpanded(board.id) }">
              <draggable v-model="board.items" group="tasks" @end="onEnd" item-key="id" class="p-2 bg-gray-50 rounded">
                <template #item="{ element }">
                  <div
                    class="bg-white p-2 rounded shadow-sm mb-2 cursor-pointer text-gray-600 flex justify-between items-center">
                    <span class="flex-grow truncate"
                      @click="openEditTaskModal($event, element.salesObjectId, board.id)">
                      {{ element.title }} - {{ element.date }}
                    </span>
                    <!-- Green Check Icon for Sent Status -->
                    <span v-if="element.sent" class="sent-icon cursor-pointer ml-2 text-green-500">
                      &#x2714; Sent
                    </span>
                    <!-- Blue Check Icon for Confirmation (only if not sent) -->
                    <span v-else-if="element.confirmed" class="check-icon cursor-pointer ml-2 text-blue-500"
                      @click.stop="toggleConfirmed(element)">
                      &#x2713;
                    </span>
                    <!-- Gray Check Icon when neither sent nor confirmed -->
                    <span v-else class="check-icon cursor-pointer ml-2 text-gray-400"
                      @click.stop="toggleConfirmed(element)">
                      &#x2713;
                    </span>
                  </div>
                </template>
              </draggable>
            </div>

            <!-- Add Task Button -->
            <div @click="openNewTaskModal($event, board.id, board.title, board.items[0]?.date || '')"
              class="add-task-icon text-blue-600 hover:text-blue-800 cursor-pointer">
              &#x2b;
            </div>
          </div>
        </div>
      </div>

      <!-- Right Side Section (Canned Campaigns and Custom Content) -->
      <div class="right-side ml-8">

        <!-- Redesigned Custom Content Section -->

        <div class="my-4 p-4 right-side-item">
          <div class="flex items-center">
            <h3 class="text-lg font-semibold text-gray-700 mr-2">Library Content</h3>
            <div class="text-blue-600 hover:text-blue-800 cursor-pointer"
              style="margin-left: 10px; margin-top: -1px; height: 40%">
              <i @click="openPersonalContentModal" class="fas fa-plus"> ADD</i> <!-- Font Awesome "+" icon -->
            </div>
          </div>
          <div @click="toggleContentExpand" class="cursor-pointer flex items-center justify-between mt-2">
            <span class="text-lg font-semibold text-gray-700">Available Content</span>
            <div class="text-gray-500 ml-2">
              <span v-if="!isContentExpanded">&#x25BC;</span> <!-- Down arrow -->
              <span v-else>&#x25B2;</span> <!-- Up arrow -->
            </div>
          </div>
          <div v-if="isContentExpanded" class="campaigns-scrollable mt-4">
            <draggable v-model="savedContent" group="tasks" item-key="id" class="p-2 bg-gray-50 rounded">
              <template #item="{ element }">
                <div
                  class="bg-white p-2 rounded shadow-sm mb-2 cursor-pointer text-gray-600 flex justify-between items-center">
                  <span class="flex-grow truncate" @click="openEditContentModal(element)">
                    {{ element.name }}
                  </span>
                  <span class="check-icon cursor-pointer ml-2 text-gray-600">
                    <!-- Font Awesome Trash Icon -->
                    <i class="fas fa-trash-alt text-red-600 cursor-pointer ml-4"
                      @click.stop="deleteContent(element)"></i></span>
                </div>
              </template>
            </draggable>
          </div>
        </div>






        <!-- Canned Campaigns Section -->
        <div class="my-4 p-4 right-side-item">
          <div class="flex items-center">
            <h3 class="text-lg font-semibold text-gray-700 mr-2">Manage Campaigns</h3>
            <div @click="openSaveCampaignModal" class="text-blue-600 hover:text-blue-800 cursor-pointer"
              style="margin-left: 10px; margin-top: -1px; height: 40%">
              <i class="fas fa-save"> SAVE</i> <!-- Font Awesome "+" icon -->
            </div>
          </div>
          <div class="flex items-center justify-between mt-2">
            <span class="cursor-pointer text-lg font-semibold text-gray-700" @click="toggleCampaignsExpand">Available
              Campaigns</span>
            <div @click="toggleCampaignsExpand" class="text-gray-500 cursor-pointer ml-2">
              <span v-if="!isCampaignsExpanded">&#x25BC;</span> <!-- Down arrow -->
              <span v-else>&#x25B2;</span> <!-- Up arrow -->
            </div>
          </div>
          <div v-if="isCampaignsExpanded" class="campaigns-scrollable mt-4">
            <draggable v-model="savedCampaigns" group="tasks" item-key="id" class="p-2 bg-gray-50 rounded">
              <template #item="{ element }">
                <div
                  class="bg-white p-2 rounded shadow-sm mb-2 cursor-pointer text-gray-600 flex justify-between items-center">
                  <span class="flex-grow truncate" @click="setCampaignTask(element)">
                    {{ element.name }}</span>
                  <span class="check-icon cursor-pointer ml-2 text-gray-600">
                    <!-- Font Awesome Trash Icon -->
                    <i v-if="element.type == 'agency'" class="fas fa-trash-alt text-red-600 cursor-pointer ml-4"
                      @click.stop="deleteCampaign(element)"></i></span>
                </div>
              </template>
            </draggable>
          </div>
        </div>





      </div>
    </div>

    <!-- Decision Maker(s) Section -->
    <div class="decision-makers-section my-4 p-4 bg-white rounded shadow-md border border-gray-200">
      <h3 class="text-lg font-semibold text-gray-700 mb-4">Decision Maker(s)</h3>

      <!-- Inputs and remove button in a single row -->
      <div v-for="(decisionMaker, index) in decisionMakers" :key="index" class="flex items-center mb-2 justify-between">
        <input type="text" v-model="decisionMaker.first_name" @input="onFieldInput(index)" @blur="onFieldBlur(index)"
          class="decision-maker-input" placeholder="First Name">
        <input type="text" v-model="decisionMaker.last_name" @input="onFieldInput(index)" @blur="onFieldBlur(index)"
          class="decision-maker-input" placeholder="Last Name">
        <input type="email" v-model="decisionMaker.email" @input="onFieldInput(index)" @blur="onFieldBlur(index)"
          class="decision-maker-input" placeholder="Email">
        <input type="text" v-model="decisionMaker.role" @input="onFieldInput(index)" @blur="onFieldBlur(index)"
          class="decision-maker-input" placeholder="Role">

        <!-- Dropdowns for decision making types -->
        <select v-model="decisionMaker.primary_decision_making_type" @change="onFieldChange(index)"
          class="decision-maker-select">
          <option disabled value="">Primary Decision Making Type</option>
          <option v-for="type in primaryTypes" :key="type" :value="type">{{ type }}</option>
        </select>
        <select v-model="decisionMaker.secondary_decision_making_type" @change="onFieldChange(index)"
          class="decision-maker-select">
          <option disabled value="">Secondary Decision Making Type</option>
          <option v-for="type in secondaryTypes" :key="type" :value="type">{{ type }}</option>
        </select>

        <button @click="removeDecisionMaker(index)" class="remove-button text-red-500">
          ✖
        </button>
      </div>

      <button @click="addDecisionMaker" class="add-decision-maker-button">+ Add Decision Maker</button>
    </div>

    <!-- Calendar Section -->
    <div class="mt-4">
      <calendar :key="calendarKey" :events="calendarEvents" id="calendar-table" />
    </div>

    <dynamic-modal :isVisible="showNewTaskModal" modalType="task" :modalPosition="modalPosition"
      :categoryName="selectedCategoryName" :defaultDate="selectedEventDate" :proposalId="proposalId"
      @close="closeNewTaskModal" @taskAdded="handleTaskAdded" @refreshTasks="fetchCalendarEvents" />

    <dynamic-modal ref="dynamicModal" :isVisible="showEditTaskModal" modalType="edit-task"
      :modalPosition="modalPosition" :categoryName="selectedCategoryName" :defaultDate="selectedTaskDate"
      :task="selectedTask" :proposalId="proposalId" @close="closeEditTaskModal" @delete="deleteTask"
      @refreshTasks="fetchCalendarEvents" />

    <personal-library-add-content-modal ref="personalLibraryModal" :isOpen="isPersonalLibraryModalOpen"
      :key="selectedContent ? selectedContent.id : 'new'" :selectedContent="selectedContent"
      @close="isPersonalLibraryModalOpen = false" @saveContent="addPersonalContent" />

    <save-campaign-modal ref="saveCampaignModal" :isOpen="isSaveCampaignModalOpen" :accountId="proposalId"
      @close="closeSaveCampaignModal" @save="handleSaveCampaign" />


    <!--personal-library-add-content-modal :isOpen="isPersonalLibraryModalOpen" :selectedContent="selectedContent" @close="isPersonalLibraryModalOpen = false" @saveContent="addPersonalContent" /-->
  </div>
</template>

<script>
import axios from 'axios';
import draggable from 'vuedraggable';
import Calendar from "../../examples/Calendar.vue";
import DynamicModal from "@/components/DynamicModal.vue";
import PersonalLibraryAddContentModal from "@/components/PersonalLibraryAddContentModal.vue";
import SaveCampaignModal from "@/components/SaveCampaignModal.vue";
import eventBus from "@/services/event-bus.service.js";

export default {
  name: "KanbanCalendarPage",
  components: { draggable, Calendar, DynamicModal, PersonalLibraryAddContentModal, SaveCampaignModal },
  data() {
    return {
      isContentExpanded: false,
      isCampaignsExpanded: false,
      savedCampaigns: [
        // Add more campaigns as needed
      ],
      savedContent: [

        // Add more campaigns as needed
      ],
      xDate: '',
      accountName: '',
      isSaveCampaignModalOpen: false, // Track Save Campaign modal visibility
      isPersonalLibraryModalOpen: false,
      isSavingDecisionMaker: false,
      expandedBoards: [], // Track expanded boards
      proposalId: this.$route.params.accountId,
      calendarEvents: [],
      boards: [],
      decisionMakers: [], // Added for managing decision makers
      originalDecisionMakers: [], // Added to keep track of the original data for comparison
      customContent: [], // Added for managing user uploaded content
      showNewTaskModal: false,
      showEditTaskModal: false, // Added for editing task modal visibility
      newTask: { title: "", date: "", description: "" },
      selectedTask: null, // Changed from currentTask to selectedTask for clarity
      calendarKey: 0,
      modalPosition: { x: 0, y: 0 }, // Added for modal position
      selectedCategoryName: '', // Added to store the selected category name
      selectedEventDate: '', // Added to store the selected event date
      selectedTaskDate: '', // Added to store the selected task date for editing
      typingTimeout: null, // Timeout ID for debouncing
      primaryTypes: [], // List for primary decision-making types
      secondaryTypes: [] // List for secondary decision-making types
    };
  },

  async mounted() {
    await this.fetchCalendarEvents();
    await this.fetchDecisionMakers(); // Load decision makers on page load
    this.initializeKanban();
    this.loadDropdownOptions(); // Load dropdown options on page load
    await this.fetchSalesObjects();  // Fetch sales objects when component loads
    await this.fetchSavedCampaigns(); // Fetch saved campaigns when component loads
  },
  methods: {
    setCampaignTask(element) {
      const campaignId = element.id; // Get the campaign ID
      const questionnaireSessionId = this.$route.params.accountId; // Get the questionnaire session ID (account ID) from the URL

      console.log("Setting Campaign ID: ", campaignId, " for Account (Questionnaire Session) ID: ", questionnaireSessionId);

      if (confirm("Are you sure you want to set this campaign for the account?")) {
        // Make an API call to set the campaign for the account
        axios.post(
          `${process.env.VUE_APP_API_BASE_URL}/campaigns/set`, // Updated the API URL
          {
            campaign_id: campaignId, // Send the campaign ID in the body
            questionnaire_session_id: questionnaireSessionId, // Send the questionnaire session ID in the body
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_token')}`, // Include authorization token
            }
          }
        )
          .then(response => {
            console.log("Campaign set successfully:", response.data);

            // Optionally, show a success message
            alert('Campaign set successfully for the account!');
          })
          .catch(error => {
            console.error("Error setting campaign:", error.response || error.message);

            // Optionally, show an error message
            alert('Failed to set campaign. Please try again.');
          });
      }
    },


    deleteCampaign(element) {
      const campaignId = element.id; // Get the campaign ID
      console.log("Deleting Campaign ID: ", campaignId);

      if (confirm("Are you sure you want to delete this campaign?")) {
        // Make an API call to delete the campaign
        axios.delete(`${process.env.VUE_APP_API_BASE_URL}/campaigns/${campaignId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        })
          .then(response => {
            console.log("Campaign deleted successfully:", response.data);

            // Remove the campaign from the savedCampaigns list
            this.savedCampaigns = this.savedCampaigns.filter(campaign => campaign.id !== campaignId);

            // Optionally, show a success message
            alert('Campaign deleted successfully!');
          })
          .catch(error => {
            console.error("Error deleting campaign:", error.response || error.message);

            // Optionally, show an error message
            alert('Failed to delete campaign. Please try again.');
          });
      }
    },

    // Open the Save Campaign modal
    openSaveCampaignModal() {
      this.isSaveCampaignModalOpen = true;
    },

    // Close the Save Campaign modal
    async closeSaveCampaignModal() {
      this.isSaveCampaignModalOpen = false;
      await this.fetchSavedCampaigns();
    },

    // Handle saving of the campaign
    handleSaveCampaign(campaignData) {
      console.log("Campaign saved with name:", campaignData.campaignName);
      this.isSaveCampaignModalOpen = false;

      // Example logic to handle the campaign data after saving:
      this.savedCampaigns.push({
        id: this.savedCampaigns.length + 1,
        name: campaignData.campaignName,
      });

      // Optionally, refresh the campaigns or trigger any other action
    },
    deleteContent(content) {
      console.log("content : ", content);
      if (confirm(`Are you sure you want to delete ${content.name}?`)) {
        this.savedContent = this.savedContent.filter(item => item.id !== content.id);
        // You might also want to send a delete request to the backend
        axios.delete(`${process.env.VUE_APP_API_BASE_URL}/delete-object/${content.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }).then(() => {
          alert('Content deleted successfully!');
        }).catch(error => {
          console.error('Error deleting content:', error);
          alert('Failed to delete content.');
        });
      }
    },
    async fetchSalesObjects() {
      const token = localStorage.getItem('access_token');
      if (!token) {
        console.error("No access token found. Please log in.");
        return;
      }

      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/sales-objects`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log("Response Objects: ", response.data.salesObjects);

        // Assign the fetched sales objects to savedContent
        this.savedContent = response.data.salesObjects.map((obj) => ({
          id: obj.id,
          name: obj.name,
          type: obj.type,
          content: obj.content,
          categories: obj.categories
        }));
      } catch (error) {
        console.error('Error fetching sales objects:', error);
      }
    },
    openEditContentModal(content) {

      console.log("content: ", content);
      this.selectedContent = content; // Pass the selected content
      this.isPersonalLibraryModalOpen = true; // Open the modal in edit mode
    },
    openPersonalContentModal() {
      console.log("Opening");
      this.selectedContent = null; // Reset the selected content

      this.$refs.personalLibraryModal.clearForm();  // Reset modal before opening

      this.isPersonalLibraryModalOpen = true;
    },
    toggleContentExpand() {
      this.isContentExpanded = !this.isContentExpanded;
    },
    toggleCampaignsExpand() {
      this.isCampaignsExpanded = !this.isCampaignsExpanded;
    },
    toggleExpand(boardId) {
      if (this.expandedBoards.includes(boardId)) {
        this.expandedBoards = this.expandedBoards.filter(id => id !== boardId);
      } else {
        this.expandedBoards.push(boardId);
      }
    },
    isExpanded(boardId) {
      return this.expandedBoards.includes(boardId);
    },
    async fetchDecisionMakers() { // ALSO GETS ACCOUNT NAME
      const token = localStorage.getItem('access_token');
      if (token) {
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/decision-makers/${this.proposalId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });


          this.accountName = response.data.client_name;
          eventBus.emit("updateHeaderTitle", this.accountName);
          eventBus.emit("toggleHeaderVisibility", true);

          this.xDate = response.data.xDate;
          const currentDate = new Date();
          const targetDate = new Date(this.xDate);
          const timeDifference = targetDate - currentDate;
          let daysToClose = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
          eventBus.emit("updateDaysToClose", daysToClose);
          eventBus.emit("toggleDaysToClose", true);
          this.decisionMakers = response.data.decision_makers.map(maker => ({
            ...maker,
            primary_decision_making_type: maker.primary_decision_making_type || '',
            secondary_decision_making_type: maker.secondary_decision_making_type || ''
          }));

          this.originalDecisionMakers = JSON.parse(JSON.stringify(this.decisionMakers));
        } catch (error) {
          console.error('Failed to fetch decision makers:', error);
        }
      } else {
        console.error("No access token found");
        alert("No access token found. Please log in.");
      }
    },

    loadDropdownOptions() {
      // Load dropdown options (you can fetch them from an API or define them here)
      this.primaryTypes = ["Dominance", "Influence", "Steadiness", "Conscientiousness"];
      this.secondaryTypes = ["Dominance", "Influence", "Steadiness", "Conscientiousness"];
    },

    hasChanges(index) {
      return JSON.stringify(this.decisionMakers[index]) !== JSON.stringify(this.originalDecisionMakers[index]);
    },
    addPersonalContent(newContent) {
      this.savedContent.push(newContent);
    },
    async saveDecisionMaker(index) {
      const decisionMaker = this.decisionMakers[index];
      if (!this.hasChanges(index)) return;
      const token = localStorage.getItem('access_token');
      try {
        let response;
        this.isSavingDecisionMaker = true;
        if (decisionMaker.id) {
          response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/decision-maker/update/${decisionMaker.id}`, decisionMaker, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        } else if (!decisionMaker.created) {
          response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/decision-maker/create/${this.proposalId}`, decisionMaker, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          this.decisionMakers[index] = { ...decisionMaker, id: response.data.decision_maker.id, created: true };
          await this.fetchDecisionMakers();
        }
        this.originalDecisionMakers[index] = JSON.parse(JSON.stringify(this.decisionMakers[index]));
      } catch (error) {
        console.error('Failed to save decision maker', error);
      }
    },

    onFieldInput(index) {
      const decisionMaker = this.decisionMakers[index];
      if (decisionMaker.id) {
        clearTimeout(this.typingTimeout);
        this.typingTimeout = setTimeout(() => {
          if (this.hasChanges(index)) {
            this.saveDecisionMaker(index);
          }
        }, 1000);
      }
    },

    onFieldBlur(index) {
      if (this.hasChanges(index)) {
        this.saveDecisionMaker(index);
      }
    },

    onFieldChange(index) {
      if (this.hasChanges(index)) {
        this.saveDecisionMaker(index);
      }
    },

    handleTaskAdded(task) {
      if (!this.decisionMakers.length) {
        alert('Please add at least one decision maker before adding tasks.');
        return;
      }
      const board = this.boards.find(board => board.id === this.selectedCategoryName.toLowerCase().replace(/ /g, "_"));
      if (board) {
        board.items.push({
          id: task.scheduled_sales_object_id,
          title: task.name,
          date: task.date,
          salesObjectId: task.scheduled_sales_object_id,
          confirmed: false,
        });
      }
    },

    openCampaignTaskModal() {
      console.log("CLICK CLICK");
    },

    // Method to fetch campaigns and populate savedCampaigns
    async fetchSavedCampaigns() {
      const token = localStorage.getItem('access_token');
      if (!token) {
        console.error("No access token found. Please log in.");
        return;
      }

      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/campaigns`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log("Response Templates: ", response.data.templates);

        // Map the response to populate the savedCampaigns array
        this.savedCampaigns = response.data.templates.map(template => ({
          id: template.id,
          name: template.name,
          type: template.type
        }));
      } catch (error) {
        console.error('Error fetching saved campaigns:', error);
      }
    },

    async fetchCalendarEvents() {
      this.proposalId = this.$route.params.accountId;
      const token = localStorage.getItem('access_token');
      if (token) {
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/calendar-new/${this.proposalId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const eventsData = response.data.original;
          this.calendarEvents = this.transformEvents(eventsData);
          this.boards = this.initializeKanban(eventsData);
          this.calendarKey += 1;
        } catch (error) {
          console.error("Failed to fetch calendar events", error);
        }
      } else {
        console.error("No access token found");
        alert("No access token found. Please log in.");
      }
    },

    transformEvents(events) {
      return events.flatMap(category => {
        return (category.events || []).map(event => {
          const dateOnly = event.scheduled_date.split('T')[0];
          return {
            title: event.sales_object_name || category.category,
            start: dateOnly,
            end: dateOnly,
            category: category.category,
            name: event.sales_object_name || 'Unnamed Event',
          };
        });
      });
    },

    initializeKanban(events) {
      if (!events || events.length === 0) {
        console.warn("No events available to initialize Kanban.");
        return [];
      }

      console.log("events: ", events)

      return events.map(category => ({
        id: category.category.toLowerCase().replace(/ /g, "_"),
        title: category.category,
        scheduled_date: category.scheduled_date.split('T')[0],
        items: (category.events || []).map(event => ({
          id: event.scheduled_sales_object_id,
          title: event.sales_object_name || category.category,
          date: event.scheduled_date.split('T')[0],
          salesObjectId: event.scheduled_sales_object_id,
          confirmed: event.confirmed || false,
          type: event.type || 'Uncategorized',
          sent: event.sent || false,
          decision_maker_name: event.decision_maker_name || "N/A",
          decision_maker_id: event.decision_maker_id || null,
          download_link: event.download_link || null,
        })),
      }));
    },

    openNewTaskModal(event, boardId, categoryName, eventDate) {
      if (!this.decisionMakers.length) {
        alert('Please add at least one decision maker before adding tasks.');
        return;
      }
      this.proposalId = this.$route.params.accountId;
      const buttonRect = event.target.getBoundingClientRect();
      this.modalPosition = {
        x: buttonRect.left + window.scrollX + buttonRect.width / 2,
        y: buttonRect.top + window.scrollY + buttonRect.height / 2
      };
      this.newTask.boardId = boardId;
      this.selectedCategoryName = categoryName;
      this.selectedEventDate = eventDate;
      this.showNewTaskModal = true;
    },

    closeNewTaskModal() {
      this.showNewTaskModal = false;
      this.newTask = { title: "", date: "", description: "" };
    },

    addNewTask() {
      if (!this.decisionMakers.length) {
        alert('Please add at least one decision maker before adding tasks.');
        return;
      }
      if (this.newTask.title && this.newTask.date) {
        const board = this.boards.find(board => board.id === this.newTask.boardId);
        const newTaskId = `_${this.newTask.title.toLowerCase().replace(/ /g, "_")}${board.id}`;
        board.items.push({
          id: newTaskId,
          title: this.newTask.title,
          date: this.newTask.date,
        });
        this.closeNewTaskModal();
      }
    },

    addDecisionMaker() {
      this.decisionMakers.push({
        first_name: '',
        last_name: '',
        email: '',
        role: '',
        primary_decision_making_type: '',
        secondary_decision_making_type: ''
      });
    },

    async removeDecisionMaker(index) {
      const decisionMaker = this.decisionMakers[index];
      const token = localStorage.getItem('access_token');
      const confirmed = window.confirm(`Are you sure you want to delete the decision maker ${decisionMaker.first_name} ${decisionMaker.last_name}?`);
      if (!confirmed) return;

      try {
        if (decisionMaker.id) {
          await axios.delete(`${process.env.VUE_APP_API_BASE_URL}/decision-makers/delete/${decisionMaker.id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          this.decisionMakers.splice(index, 1);
          this.originalDecisionMakers.splice(index, 1);
        }
      } catch (error) {
        console.error('Failed to delete decision maker', error);
      }
    },

    openEditTaskModal(event, taskId, boardId) {
      const board = this.boards.find(board => board.id === boardId);
      if (board) {
        const taskData = board.items.find(task => task.salesObjectId === taskId);
        if (taskData) {
          const taskRect = event.target.getBoundingClientRect();
          this.modalPosition = {
            x: taskRect.left + window.scrollX + taskRect.width / 2,
            y: taskRect.top + window.scrollY + taskRect.height / 2
          };
          this.selectedTask = taskData;
          this.selectedTaskDate = taskData.date;
          this.selectedCategoryName = boardId;
          this.showEditTaskModal = true;
        } else {
          console.error("Task data not found");
        }
      } else {
        console.error("Board not found");
      }
    },

    closeEditTaskModal() {
      this.showEditTaskModal = false;
      this.selectedTask = null;
    },

    deleteTask(taskId) {
      this.boards = this.boards.map(board => ({
        ...board,
        items: board.items.filter(item => item.salesObjectId !== taskId)
      }));
      this.closeEditTaskModal();
    },

    toggleConfirmed(task) {
      this.selectedTask = task;
      this.modalPosition = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
      this.showEditTaskModal = true;
      this.$nextTick(() => {
        const dynamicModal = this.$refs.dynamicModal;
        if (dynamicModal) {
          dynamicModal.selectedTask = task;
          dynamicModal.step = 5;
          dynamicModal.showTaskPreview(task.salesObjectId, task.content || 'Default content', 1);
        } else {
          console.error("DynamicModal ref is undefined");
        }
      });
    },

    onEnd(event) {
      console.log("Drag ended", event);
    }
  },
  beforeUnmount() {
    eventBus.emit("toggleHeaderVisibility", false); // Hide the header on unmount
    eventBus.emit("toggleDaysToClose", false);

  },
  activated() {
    eventBus.emit("toggleHeaderVisibility", true);
    eventBus.emit("toggleDaysToClose", true);

  }
};
</script>

<style scoped>
.campaigns-scrollable {
  overflow-y: auto;
  max-height: 225px;
  transition: max-height 0.3s ease;
}

.campaigns-scrollable.expanded {
  max-height: 300px;
  /* Adjust as needed */
}


.kanban-grid {
  display: flex;
  flex-direction: column;
  grid-template-rows: repeat(9, auto);
  gap: 10px;
  justify-items: center;
}

.right-side {
  padding-left: 500px;
  width: 40%;
}

.kanban-column {
  display: flex;
  flex-direction: column;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 0.75rem;
  padding: 5px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  max-height: 80px;
  width: 100%;
  overflow: hidden;
  transition: max-height 0.3s ease;
  justify-items: center
}

.kanban-column.expanded-kanban-column {
  max-height: 500px;
}

.kanban-scrollable {
  overflow-y: auto;
  max-height: 75px;
  transition: max-height 0.3s ease;
}

.kanban-scrollable.expanded {
  max-height: 300px;
}

.add-task-icon {
  font-size: 18px;
  text-align: center;
  margin-top: 5px;
}

.right-side-item {
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 0.75rem;
  padding: 5px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  width: 320px;
}

.right-side-scrollable {
  max-height: 350px;
  overflow-y: auto;
}

.bg-white {
  background-color: #fff;
}

.text-gray-600 {
  color: #718096;
}

.text-blue-600:hover {
  color: #2b6cb0;
}

.p-2 {
  padding: 0.5rem;
}

.rounded {
  border-radius: 0.375rem;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
}

.check-icon {
  font-size: 18px;
  cursor: pointer;
  transition: color 0.3s;
}

.text-blue-500 {
  color: #4299e1;
}

.text-gray-400 {
  color: #cbd5e0;
}

.expanded-content {
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 0.375rem;
  margin-top: 10px;
}

.collapse-btn {
  display: block;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
}

.decision-makers-section {
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 0.75rem;
  padding: 10px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

.decision-maker-input {
  flex: 1;
  max-width: 15%;
  margin-right: 10px;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 0.375rem;
  box-sizing: border-box;
}

.decision-maker-select {
  flex: 1;
  max-width: 15%;
  margin-right: 10px;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 0.375rem;
  box-sizing: border-box;
}

.remove-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  line-height: 1;
  margin-left: 10px;
}

.add-decision-maker-button {
  background-color: #4299e1;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.3s;
  border: none;
}

.add-decision-maker-button:hover {
  background-color: #2b6cb0;
}

.flex {
  display: flex;
  align-items: flex-start;
}

.justify-between {
  justify-content: space-between;
}

.ml-2 {
  margin-left: 8px;
}
</style>
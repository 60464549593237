<template>
  <div v-if="loaded" class="py-4 h-100 container-fluid">
    <div class="row">
      <div class="text-center col-12">
        <h3 class="mt-5 text-white">Edit your style</h3>
        <h5 class="text-white font-weight-normal">
          This is where you set the logo and colors for your agency styled reports.
        </h5>
        <br><br><br><br>
        <div class="mb-5">
          <div class="row justify-content-center">
            <div class="m-auto col-12 col-lg-8">
              <form class="bg-white p-3 card border-radius-xl" @submit.prevent="updateStyle">
                <div class="form-group d-flex justify-content-around align-items-center">
                  <div class="text-center">
                    <label class="form-label">Primary Color</label>
                    <input type="color" v-model="currentStyle.primary" class="form-control" style="width: 100px; height: 100px; border: none;">
                  </div>
                  <div class="text-center">
                    <label class="form-label">Text Color</label>
                    <input type="color" v-model="currentStyle.text" class="form-control" style="width: 100px; height: 100px; border: none;">
                  </div>
                </div>
                <div class="form-group text-center mt-4">
                  <label class="form-label">Logo</label><br>
                  <div class="logo-preview">
                    <img v-if="currentStyle.logo" :src="currentStyle.logo" alt="Logo" class="img-thumbnail" />
                    <div v-else class="placeholder">
                      <i class="fa fa-upload"></i>
                    </div>
                    <input type="file" class="form-control-file" @change="onFileChange">
                  </div>
                </div>
                <div class="form-group text-center mt-3">
  <label>Contact Phone Number</label>
  <input class="form-control mx-auto" type="text" v-model="contactNumber" placeholder="(123) 456-7890" @input="formatPhoneNumber" style="max-width: 300px;">
</div>
                <!--div class="form-check text-center mt-3 d-flex justify-content-center align-items-center">
                  <input type="checkbox" class="form-check-input" id="restoreStyle" v-model="restoreStyle" style="margin-right: 10px; margin-bottom: 10px;">
                  <label class="form-check-label align-middle" for="restoreStyle">Restore Previous Style</label>
                </div-->
                <div class="d-flex justify-content-end mt-4">
                  <button type="submit" class="btn btn-primary me-2">Update Style</button>
                  <button type="button" class="btn btn-secondary" @click="previewStyle">Preview Style</button>
                </div>
              </form>
              <div v-if="message" class="alert" :class="{'alert-success': success, 'alert-danger': !success}" role="alert">
                {{ message }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="loading">
    Loading styles...
  </div>
</template>

<script>
import axios from "axios";

const API_URL = process.env.VUE_APP_API_BASE_URL;
const authHeader = () => ({
  Authorization: `Bearer ${localStorage.getItem("access_token")}`
});

export default {
  name: "EditStyle",
  data() {
    return {
      loaded: false,
      restoreStyle: false,
      message: null,
      success: false,
      currentStyle: {
        primary: "#000000", // Default color
        text: "#000000",    // Default color
        logo: null
      },
      contactNumber: ""
    };
  },
  async created() {
  try {
    const response = await this.fetchAgencyStyles();
    const { style, phone } = response.data;

    // Accessing colors and logo from the nested 'style' object
    this.currentStyle.primary = `#${style.primary_color1.toString(16).padStart(6, '0')}`;
    this.currentStyle.text = `#${style.primary_color2.toString(16).padStart(6, '0')}`;
    this.currentStyle.logo = style.logo ? `data:image/png;base64,${style.logo}` : null;
    this.contactNumber = phone || "";
    this.agencyId = style.agency_id;

    this.loaded = true;
  } catch (error) {
    console.error("Error loading agency styles:", error);
    this.message = "Failed to load styles. Please try again.";
    this.success = false;
  }
},
  methods: {
    async fetchAgencyStyles() {
      try {
        const response = await axios.get(`${API_URL}/agency/styles`, { headers: authHeader() });
        //console.log("style : ", response.data);
        return response.data;
      } catch (error) {
        console.error("Error fetching agency styles:", error);
        throw error;
      }
    },
    async updateStyle() {
      try {
        const styleData = {
          primary_color1: parseInt(this.currentStyle.primary.slice(1), 16),
          primary_color2: parseInt(this.currentStyle.text.slice(1), 16),
          logo: this.currentStyle.logo.split(",")[1], // Only the base64 part
          phone: this.contactNumber,
          restore_previous_style: this.restoreStyle
        };

        const token = localStorage.getItem("access_token");
        if (!token) {
          console.error("No access token found in localStorage");
          this.message = "No access token found. Please log in.";
          this.success = false;
          return;
        }

        const response = await axios.post(
          `${API_URL}/agency/update-style`,
          styleData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/vnd.api+json",
              "Content-Type": "application/vnd.api+json"
            }
          }
        );

        console.log("Response Status:", response.status);
        console.log("Response Headers:", response.headers);
        console.log("Response Data:", response.data);

        this.success = true;
        this.message = "Style saved successfully.";
      } catch (error) {
        console.error("Error updating agency style:", error);

        if (error.response) {
          console.log("Error Response Status:", error.response.status);
          console.log("Error Response Data:", error.response.data);
          this.message = error.response.data?.message || "Failed to save the style.";
        } else if (error.request) {
          console.error("Error Request Data:", error.request);
          this.message = "No response received from the server.";
        } else {
          console.error("Error:", error.message);
          this.message = "An error occurred while saving the style.";
        }

        this.success = false;
      }

      setTimeout(() => {
        this.message = null;
        if (this.restoreStyle) {
          window.location.reload();
        }
      }, 2500);
    },
    onFileChange(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.currentStyle.logo = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    formatPhoneNumber(event) {
      let input = event.target.value.replace(/\D/g, "").substring(0, 10);
      const areaCode = input.substring(0, 3);
      const middle = input.substring(3, 6);
      const last = input.substring(6, 10);

      if (input.length > 6) {
        this.contactNumber = `(${areaCode}) ${middle}-${last}`;
      } else if (input.length > 3) {
        this.contactNumber = `(${areaCode}) ${middle}`;
      } else if (input.length > 0) {
        this.contactNumber = `(${areaCode}`;
      } else {
        this.contactNumber = input;
      }
    },
    previewStyle() {
      if (!this.agencyId) {
        alert("Agency ID not found");
        return;
      }

      const baseUrl = process.env.VUE_APP_API_BASE_URL.replace("/api/vue", "");
      const previewUrl = `${baseUrl}/reports/preview/${this.agencyId}`;

      const newWindow = window.open("", "_blank");
      if (newWindow) {
        newWindow.document.write("<p>Loading...</p>");

        setTimeout(() => {
          newWindow.location.href = previewUrl;
        }, 1000);
      } else {
        alert("Please allow popups for this website");
      }
    }
  }
};
</script>


<style scoped>
.img-thumbnail {
  width: 100%;
  max-width: 500px;
  height: auto;
}
.logo-preview {
  position: relative;
  display: inline-block;
}
.logo-preview .placeholder {
  width: 300px;
  height: 150px;
  border: 1px dashed #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.logo-preview input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.alert {
  margin-top: 20px;
}
</style>

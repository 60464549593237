<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <div class="card-header d-flex justify-content-between align-items-center">
            <h5 class="mb-0">{{ selectedView === 'income' ? 'Income Goals' : 'Presentation Goals' }}</h5>
            <select v-model="selectedView" class="form-select w-auto custom-select" @change="initTables">
              <option value="income">Income</option>
              <option value="presentations">Presentations</option>
            </select>
          </div>
          <div v-if="selectedView === 'income'" class="table-responsive">
            <table :key="tableKey" ref="datatableIncome" id="datatable-income" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>Producer Name</th>
                  <th>Confidence Level</th>
                  <th>Income Annual Goal</th>
                  <th>YTD Expected<br>YTD Income Goal</th>
                  <th>YTD Actual</th>
                  <th>YTD Progress (%)</th>

                  <th>Annual Goal (%)</th>
                  <th>Projected Year Income<br>At current pace</th>

                  <th>Manage Goal</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="goal in agencyGoals" :key="goal.agent_name">
                  <td>{{ goal.agent_name }}</td>
                  <td :id="'confidence_' + goal.user_id" :class="{'click-to-set': goal.goals?.confidence_level === 0}">
                    <div v-if="editingGoal === goal.user_id">
                      <input ref="confidenceInput" type="number" v-model.number="goal.goals.confidence_level" @blur="saveConfidenceLevel(goal)" @keydown.enter="saveConfidenceLevel(goal)" class="form-control"/>
                    </div>
                    <div v-else @click="enableEditing(goal.user_id)" style="cursor: pointer;">
                      {{ (goal.goals === null || goal.goals?.confidence_level === null) ? 'N/A' : (goal.goals?.confidence_level === 0 ? 'Click to set' : goal.goals?.confidence_level + '%') }}
                    </div>
                  </td>

                  <td>{{ formatCurrency(goal.calculated.new_production_required) ?? 'N/A' }}</td>
                  <td>{{ formatCurrency(incomeYTDExpected(goal)) }}</td>

                  <td>{{ formatCurrency(goal.calculated.ytd_commission) ?? 'N/A' }}</td>
                  <td>{{ incomeYTDExpected(goal) !== 'N/A' ? (goal.calculated.ytd_commission / incomeYTDExpected(goal) * 100).toFixed(2) + '%' : 'N/A' }}</td>

                  <td>{{ goal.calculated.new_production_required ? ((goal.calculated.ytd_commission / goal.calculated.new_production_required * 100).toFixed(2)) + '%' : 'N/A' }}</td>

                  <td>{{ calculateProRatedIncome(goal) }}</td> 


                  <td>
                    <button @click="goToBusinessPlan(goal.user_id)" :id="'plan_button_' + goal.user_id" style="margin-top: 5px;" class="btn btn-success btn-sm padding-right">Producer Plan</button>
                  </td>
                </tr>

                <tr>
                  <td><strong>Totals</strong></td>
                  <td>{{ averageConfidenceLevel }}</td>
                  <td>{{ formatCurrency(aggregateTotals.incomeAnnualGoal) }}</td>
                  <td>{{ formatCurrency(aggregateTotals.onProgressYTDExpected) }}</td>

                  <td>{{ formatCurrency(aggregateTotals.incomeYTDActual) }}</td>
                  <td>{{ averageGoalPercentage(incomeYTDProgressPercentages) }}</td>

                  <td>{{ averageGoalPercentage(incomeGoalPercentages) }}</td>
                  <td>{{ formatCurrency(aggregateTotals.incomeYearProRated) }}</td>

                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else class="table-responsive">
            <table :key="tableKey" ref="datatablePresentations" id="datatable-presentations" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>Producer Name</th>
                  <th>Annual Presentations Goal</th>
                  <th>Presentations YTD (Actual)</th>
                  <th>Annual Goal (%)</th>
                  <th>Presentations YTD (Expected)</th>
                  <th>Presentations YTD Progress</th>
                  <th>Manage Goal</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="goal in agencyGoals" :key="goal.agent_name">
                  <td>{{ goal.agent_name }}</td>
                  <td>{{ goal.calculated.presentations_per_month ? Math.round(goal.calculated.presentations_per_month * 12) : 'N/A' }}</td>
                  <td>{{ goal.proposal_count ?? 'N/A' }}</td>
                  <td>{{ goal.calculated.presentations_per_month ? Math.round((goal.proposal_count / (goal.calculated.presentations_per_month * 12) * 100)) + '%' : 'N/A' }}</td>
                  <td>{{ presentationsYTDExpected(goal) }}</td>
                  <td>{{ presentationsYTDExpected(goal) !== 'N/A' ? (goal.proposal_count / presentationsYTDExpected(goal) * 100).toFixed(2) + '%' : 'N/A' }}</td>
                  <td>
                    <button @click="goToBusinessPlan(goal.user_id)" :id="'plan_button_' + goal.user_id" style="margin-top: 5px;" class="btn btn-success btn-sm padding-right">Producer Plan</button>
                  </td>
                </tr>
                <tr>
                  <td><strong>Totals</strong></td>
                  <td>{{ (aggregateTotals.presentationsAnnualGoal) }}</td>
                  <td>{{ (aggregateTotals.presentationsYTDActual) }}</td>
                  <td>{{ averageGoalPercentage(presentationsGoalPercentages) }}</td>
                  <td>{{ (aggregateTotals.presentationsYTDExpected) }}</td>
                  <td>{{ averageGoalPercentage(presentationsYTDProgressPercentages) }}</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <p v-if="!agencyGoals.length" class="mt-4">No goals found.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DataTable } from "simple-datatables";
import axios from 'axios';

export default {
  name: "AgencyPlan",
  data() {
    return {
      agencyGoals: [],
      selectedView: 'income',
      incomeTable: null,
      presentationsTable: null,
      editingGoal: null,
      tableKey: 0,
      aggregateTotals: {
        incomeAnnualGoal: 0,
        incomeYTDActual: 0,
        onProgressYTDExpected: 0,
        incomeYearProRated: 0,
        presentationsAnnualGoal: 0,
        presentationsYTDActual: 0,
        presentationsYTDExpected: 0
      },
      incomeGoalPercentages: [],
      incomeYTDProgressPercentages: [],
      presentationsGoalPercentages: [],
      presentationsYTDProgressPercentages: []
    };
  },
  async mounted() {
    await this.loadGoals();
    this.initTables();
    this.calculateAggregates();
    this.bindOnClick();
  },
  watch: {
    agencyGoals() {
      this.initTables();
      this.calculateAggregates();
    },
    selectedView() {
      this.initTables();
    }
  },
  computed: {
    currentDate() {
      return new Date();
    },
    startOfYear() {
      return new Date(this.currentDate.getFullYear(), 0, 1);
    },
    daysInYear() {
      const endOfYear = new Date(this.currentDate.getFullYear(), 11, 31);
      return (endOfYear - this.startOfYear) / (1000 * 60 * 60 * 24);
    },
    daysPassed() {
      return (this.currentDate - this.startOfYear) / (1000 * 60 * 60 * 24);
    },
    incomeYTDExpected() {
      return (goal) => {
        if (goal.calculated.new_production_required) {
          return ((this.daysPassed / this.daysInYear) * goal.calculated.new_production_required).toFixed(2);
        }
        return 'N/A';
      };
    },
    presentationsYTDExpected() {
      return (goal) => {
        if (goal.calculated.presentations_per_month) {
          const annualPresentationsGoal = Math.round(goal.calculated.presentations_per_month * 12);
          return Math.round((this.daysPassed / this.daysInYear) * annualPresentationsGoal);
        }
        return 'N/A';
      };
    },
    averageConfidenceLevel() {
      const validConfidenceLevels = this.agencyGoals
        .map(goal => goal.goals?.confidence_level)
        .filter(confidence_level => confidence_level !== null && confidence_level !== undefined);

      if (validConfidenceLevels.length === 0) return 'N/A';

      const total = validConfidenceLevels.reduce((sum, confidence_level) => sum + confidence_level, 0);
      return (total / validConfidenceLevels.length).toFixed(2) + '%';
    }
  },
  methods: {
    calculateProRatedIncome(goal) {
      if (goal.calculated.ytd_commission) {
        // Calculate pro-rated income by scaling YTD income by the ratio of days passed
        const proRatedIncome = (goal.calculated.ytd_commission / this.daysPassed) * this.daysInYear;
        return this.formatCurrency(proRatedIncome);
      }
      return 'N/A';
    },

    calculateProRatedPercentage(goal) {
      if (goal.calculated.ytd_commission && goal.calculated.new_production_required) {
        // Scale up the YTD income based on the days passed to project for the full year
        const projectedIncome = (goal.calculated.ytd_commission / this.daysPassed) * this.daysInYear;
        // Calculate the percentage of the projected income against the full-year goal
        const proRatedPercentage = (projectedIncome / goal.calculated.new_production_required) * 100;
        return proRatedPercentage.toFixed(2) + '%';
      }
      return 'N/A';
    },

    async loadGoals() {
      const token = localStorage.getItem('access_token');
      if (token) {
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/agency/goals`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            }
          });
          console.log(response);
          this.agencyGoals = response.data.data;
          this.agencyGoals.forEach(goal => {
            if (goal.calculated && goal.goals?.confidence_level) {
              // Overwrite new_production_required by scaling with confidence level
              goal.calculated.new_production_required *= (goal.goals.confidence_level / 100);
            }
          });
        } catch (error) {
          console.error('Error fetching agency goals', error);
          alert('Failed to fetch agency goals. Please try again.');
        }
      } else {
        console.error('No access token found in localStorage');
        alert('No access token found. Please log in.');
      }
    },

    goToBusinessPlan(userId) {
      this.$router.push({ name: 'Business Plan', params: { user_id: userId } });
    },

    formatCurrency(value) {
      if (value === null || value === undefined || isNaN(value)) return 'N/A';
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(value);
    },

    initTables() {
      this.$nextTick(() => {
        if (this.incomeTable) {
          this.incomeTable.destroy();
          this.incomeTable = null;
        }
        if (this.presentationsTable) {
          this.presentationsTable.destroy();
          this.presentationsTable = null;
        }
        if (this.selectedView === 'income') {
          this.incomeTable = new DataTable(this.$refs.datatableIncome, {
            searchable: true,
            fixedHeight: true
          });
        } else {
          this.presentationsTable = new DataTable(this.$refs.datatablePresentations, {
            searchable: true,
            fixedHeight: true
          });
        }
        this.bindOnClick(); // Ensure event listeners are reattached after table initialization
      });
    },

    calculateAggregates() {
      this.aggregateTotals = {
        incomeAnnualGoal: 0,
        incomeYTDActual: 0,
        onProgressYTDExpected: 0,
        incomeYearProRated: 0,
        presentationsAnnualGoal: 0,
        presentationsYTDActual: 0,
        presentationsYTDExpected: 0
      };

      this.incomeGoalPercentages = [];
      this.incomeYTDProgressPercentages = [];
      this.presentationsGoalPercentages = [];
      this.presentationsYTDProgressPercentages = [];

      this.agencyGoals.forEach(goal => {
        if (goal.calculated.new_production_required) {
          this.aggregateTotals.incomeAnnualGoal += parseFloat(goal.calculated.new_production_required);
        }
        if (goal.calculated.ytd_commission) {
          this.aggregateTotals.incomeYTDActual += parseFloat(goal.calculated.ytd_commission);
          const proRatedIncome = (goal.calculated.ytd_commission / this.daysPassed) * this.daysInYear;
          this.aggregateTotals.incomeYearProRated += proRatedIncome;
        }
        if (this.incomeYTDExpected(goal) !== 'N/A') {
          this.aggregateTotals.onProgressYTDExpected += parseFloat(this.incomeYTDExpected(goal));
        }
        if (goal.calculated.new_production_required && goal.calculated.ytd_commission !== null) {
          this.incomeGoalPercentages.push((goal.calculated.ytd_commission / goal.calculated.new_production_required) * 100);
        }
        if (this.incomeYTDExpected(goal) !== 'N/A' && goal.calculated.ytd_commission !== null) {
          this.incomeYTDProgressPercentages.push((goal.calculated.ytd_commission / parseFloat(this.incomeYTDExpected(goal))) * 100);
        }
        if (goal.calculated.presentations_per_month) {
          this.aggregateTotals.presentationsAnnualGoal += Math.round(goal.calculated.presentations_per_month * 12);
        }
        if (goal.proposal_count) {
          this.aggregateTotals.presentationsYTDActual += parseFloat(goal.proposal_count);
        }
        if (this.presentationsYTDExpected(goal) !== 'N/A') {
          this.aggregateTotals.presentationsYTDExpected += parseFloat(this.presentationsYTDExpected(goal));
        }
        if (goal.calculated.presentations_per_month && goal.proposal_count !== null) {
          this.presentationsGoalPercentages.push((goal.proposal_count / (goal.calculated.presentations_per_month * 12)) * 100);
        }
        if (this.presentationsYTDExpected(goal) !== 'N/A' && goal.proposal_count !== null) {
          this.presentationsYTDProgressPercentages.push((goal.proposal_count / parseFloat(this.presentationsYTDExpected(goal))) * 100);
        }
      });
    },

    averageGoalPercentage(percentages) {
      if (percentages.length === 0) return 'N/A';
      const validPercentages = percentages.filter(p => !isNaN(p));
      if (validPercentages.length === 0) return 'N/A';
      const total = validPercentages.reduce((acc, percentage) => acc + percentage, 0);
      return (total / validPercentages.length).toFixed(2) + '%';
    },

    enableEditing(userId) {
      this.editingGoal = userId;
      this.tableKey += 1; // Force re-render by changing the key
      this.$nextTick(() => {
        const inputElement = this.$refs.confidenceInput;
        if (inputElement) {
          inputElement.focus();
          inputElement.select(); // Preselect the input value
        }
        this.bindOnClick();
      });
    },

    saveConfidenceLevel(goal) {
  const token = localStorage.getItem('access_token');
  if (token) {
    axios.put(`${process.env.VUE_APP_API_BASE_URL}/confidence/${goal.user_id}`, {
      confidence_level: goal.goals.confidence_level
    }, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    })
    .then(response => {
      console.log('Confidence level saved', response.data);

      // Add a 200ms delay before reloading the page
      setTimeout(() => {
        window.location.reload();  // This forces a full page reload to update everything
      }, 120);  // 200ms delay

    })
    .catch(error => {
      console.error('Error saving confidence level', error);
      alert('Failed to save confidence level. Please try again.');
    });
  } else {
    console.error('No access token found in localStorage');
    alert('No access token found. Please log in.');
  }
},




    bindOnClick() {
      this.$nextTick(() => {
        this.agencyGoals.forEach(goal => {
          const confidenceElement = document.getElementById(`confidence_${goal.user_id}`);
          if (confidenceElement) {
            confidenceElement.onclick = () => this.enableEditing(goal.user_id);
          }

          const planButton = document.getElementById(`plan_button_${goal.user_id}`);
          if (planButton) {
            planButton.onclick = () => this.goToBusinessPlan(goal.user_id);
          }
        });
      });
    }
  }
};
</script>

<style scoped>
.table th, .table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #e9ecef;
}
.action-link {
  display: inline-block;
  margin-right: 10px;
  color: #007bff;
  cursor: pointer;
}
.action-link i {
  margin-right: 5px;
}

/* Custom select style */
.custom-select-wrapper {
  position: relative;
  display: inline-block;
}

.custom-select {
  padding-right: 2.25rem;
  appearance: none;
}

.custom-select-icon {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  pointer-events: none;
  color: #007bff;
}

.custom-select:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.click-to-set {
  cursor: pointer;
}
</style>

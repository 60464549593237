<template>
  <div
    :class="`card card-background card-background-mask-${background.overlay} h-100`"
  >
    <div
      class="full-background"
      :style="{
        backgroundImage: `url('${background.image}')`,
      }"
    ></div>
    <div class="card-body pt-4 text-center">
      <h6 class="text-white mb-0">{{ title }}</h6>
      <h3 class="text-white">{{ count }}</h3>
      <span :class="`badge d-block bg-gradient-${badge.color} mb-2`">
        {{ badge.label }}
      </span>
      <p>{{ description }}</p>
      <button
        v-if="action.label"
        @click="handleClick"
        class="btn btn-outline-white btn-sm px-5 mb-0"
      >
        {{ action.label }}
      </button>
    </div>
  </div>
</template>

<script>
import backImage from "@/assets/img/img-1-1200x1000.jpg";

export default {
  name: "StatisticsCard",
  props: {
    background: {
      type: Object,
      default: () => ({
        image: backImage,
        overlay: "info",
      }),
    },
    title: {
      type: String,
      default: "Statistics Card",
    },
    description: {
      type: String,
      default: "",
    },
    count: {
      type: [Number, String],
      default: "",
    },
    badge: {
      type: Object,
      default: () => ({
        color: "dark",
        label: "",
      }),
    },
    action: {
      type: Object,
      default: () => ({
        label: "",
      }),
    },
    onClick: {
      type: Function,
      default: null,
    },
  },
  methods: {
    handleClick() {
      // Call the onClick function if provided
      if (this.onClick && typeof this.onClick === 'function') {
        this.onClick();
      }
    },
  },
};
</script>

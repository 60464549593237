<!--
=========================================================
* Vue Argon Dashboard 2 PRO - v3.0.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-argon-dashboard-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
-->
<!-- TEST -->
<template>
  <div
    v-show="layout === 'landing'"
    class="landing-bg h-100 bg-gradient-primary position-fixed w-100"
  ></div>
  <sidenav v-if="showSidenav" />
  <main class="main-content position-relative max-height-vh-100 h-100">
    <navbar v-if="showNavbar" />
    <router-view />
    <app-footer v-show="showFooter" />

    <!-- Updated dynamic modal with new props -->
    <dynamic-modal
      :isVisible="showModalFlag"
      :modalType="modalType"
      :modalTitle="modalTitle"
      :modalEvents="modalEvents"
      :modalDate="modalDate"
      :modalPosition="modalPosition"
      @close="triggerHideModal"
    />
  </main>
</template>

<script>
import Sidenav from "./examples/Sidenav";
import Navbar from "@/examples/Navbars/Navbar.vue";
import AppFooter from "@/examples/Footer.vue";
import DynamicModal from "@/components/DynamicModal.vue";

import { mapState, mapActions } from "vuex";

export default {
  name: "App",
  components: {
    Sidenav,
    Navbar,
    AppFooter,
    DynamicModal,
  },
  data() {
    return {
      modalType: 'event', // default modal type
      modalTitle: 'Event Details',
    };
  },
  computed: {
    ...mapState('modal', [
      'showModalFlag',
      'modalEvents',
      'modalDate',
      'modalPosition'
    ]),
    ...mapState([
      "layout",
      "showSidenav",
      "showNavbar",
      "showFooter",
      "showConfig",
      "hideConfigButton"
    ])
  },
  methods: {
    ...mapActions('modal', ['hideModal']),
    triggerHideModal() {
      this.hideModal();
    },
    showTaskModal() {
      this.modalType = 'task';
      this.modalTitle = 'Select a Task';
      this.showModalFlag = true;
    }
  },
  mounted() {
    (function(c, s, q, u, a, r, e) {
      c.hj = c.hj || function() {
        (c.hj.q = c.hj.q || []).push(arguments);
      };
      c._hjSettings = { hjid: a };
      r = s.getElementsByTagName('head')[0];
      e = s.createElement('script');
      e.async = true;
      e.src = q + c._hjSettings.hjid + u;
      r.appendChild(e);
    })(window, document, 'https://static.hj.contentsquare.net/c/csq-', '.js', 5196653);
  },
};
</script>
